<template>
  <div>
    <Divider dashed style="color:#fff">资源分布统计</Divider>
    <div>
      <Table
        stripe
        :data="tableData"
        :columns="tableColumns"
      ></Table>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      query: {
        assetId: null,
        endDate: '',
        keyword: '',
        position: null,
        // resourcetypeId: null,
        startDate: '',
        stationId: null,
        uninstallapply: null
      },
      tableColumns: [
        {
          title: '资源类型',
          render: (h, params) => {
            return h('a', {
              style: { color: this.selectedResourcetypeId === params.row.resourceTypeId ? '#FA8B59' : '#fff' },
              on: {
                click: () => {
                  this.selectedResourcetypeId = params.row.resourceTypeId
                  this.$store.dispatch('getUninstallData', { resourcetypeId: this.selectedResourcetypeId })
                }
              }
            }, params.row.resourceTypeName)
          }
        },
        { title: '数量', align: 'right', key: 'count' }
      ]
    }
  },
  computed: {
    tableData () {
      return this.$store.state.workOrder.resourcetypeCount
    },
    selectedResourcetypeId: {
      get () {
        return this.$store.state.workOrder.selectedResourcetypeId
      },
      set (val) {
        this.$store.commit('set_selected_resourcetype_id', val)
      }
    }
  }
  // created () {
  //   this.getTableData()
  // },
  // methods: {
  //   getTableData () {
  //     this.$store.dispatch('getUninstallResourceData', this.query)
  //   }
  // }
}
</script>
